import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import kaavyaLogo from '../assets/images/kaavya_logo.png';

class Navbar extends Component {
	state = {};

	render() {
		return (
			<nav className="navbar navbar-expand-lg navbar-dark default-color sticky-top">
				<div className="container">
					<a className="navbar-brand" href="/">
						<img src={kaavyaLogo} width="80" height="32" alt="Kaavya Boutique Logo" />
					</a>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarNav">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item active">
								<a className="nav-link" href="/">
									Home
								</a>
							</li>
							<li className="nav-item dropdown">
								<a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true"> <span class="nav-label">Women</span> <span class="caret"></span></a>
								<ul className="dropdown-menu dropdown-menu-items">
									<li><Link className="nav-link" to="/collection/3">Lehenga</Link></li>
									<li><Link className="nav-link" to="/collection/1">Kurtha</Link></li>
									<li><Link className="nav-link" to="/collection/7">Saree</Link></li>
								</ul>
							</li>
							<li className="dropdown nav-item">
								<a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true"> <span class="nav-label">Men</span> <span class="caret"></span></a>
								<ul className="dropdown-menu dropdown-menu-items">
									<li><a href="#">Kurtha</a></li>
								</ul>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="http://measure.kaavyaboutique.com/">
									Measurement
								</a>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/collection/8">Client Diaries</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/about-us">About Us</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/contact-us">Contact Us</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

export default Navbar;
