import React from 'react';
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from 'mdbreact';

const CaraouselSlider = props => {
	return (
		<MDBCarousel activeItem={1} interval={3000} length={props.data.length} showControls={true} showIndicators={true} className="z-depth-1">
			<MDBCarouselInner>
				{props.data &&
					props.data.map((m, idx) => (
						<MDBCarouselItem itemId={idx + 1} key={m.id}>
							<MDBView>
								<img className="d-block w-100" src={m.imageUrl} alt={m.name} style={{ objectPosition: `${m.alignment} center` }} />
								<MDBMask overlay="black-light" />
							</MDBView>
							<MDBCarouselCaption>
								{/* <span>{m.name}</span> */}
								<h3 className="h3-responsive">{m.title}</h3>
								{/* <a href="__blank">Shop Now</a> */}
							</MDBCarouselCaption>
						</MDBCarouselItem>
					))}
			</MDBCarouselInner>
		</MDBCarousel>
	);
};

export default CaraouselSlider;
