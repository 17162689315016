import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { 
// 	Nav, 
// 	Navbar,
// 	Container,
// 	NavDropdown,
// 	MDBNavbar,
// } 
// from 'mdbootstrap';
import {
	MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
	MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from "mdbreact";
import kaavyaLogo from '../assets/images/kaavya_logo.png';

class NavbarTitle extends Component {
	state = {
		isOpen: false
	};
	  
	toggleCollapse = () => {
		this.setState({ isOpen: !this.state.isOpen });
	}


	render() {
		return (
			<MDBNavbar  expand="lg" dark sticky="top">
				<div className="container">
					<MDBNavbarBrand href="/">
						<img src={kaavyaLogo} width="80" height="32" alt="Kaavya Boutique Logo" />
					</MDBNavbarBrand>
					<MDBNavbarToggler className="navbar-toggler" onClick={this.toggleCollapse} type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</MDBNavbarToggler>
					<MDBCollapse id="navbarNav" isOpen={this.state.isOpen} navbar>
						<MDBNavbarNav right className=" ml-auto">
							<MDBNavItem active>
								<a className="nav-link" href="/">Home</a>
							</MDBNavItem>
							<MDBNavItem>
								<MDBDropdown>
									<MDBDropdownToggle nav caret>
										<span className="mr-2">Women</span>
									</MDBDropdownToggle>
									<MDBDropdownMenu className="dropdown-default">
										<MDBNavLink className="navbar-item" to="/collection/3" onClick={this.toggleCollapse}>Lehenga</MDBNavLink>
										<MDBNavLink to="/collection/1" onClick={this.toggleCollapse}>Kurtha</MDBNavLink>
										<MDBNavLink to="/collection/7" onClick={this.toggleCollapse}>Saree</MDBNavLink>
										{/* <MDBDropdownItem href="/collection/3">Lehenga</MDBDropdownItem>
										<MDBDropdownItem href="/collection/1">Kurtha</MDBDropdownItem>
										<MDBDropdownItem href="/collection/7">Saree</MDBDropdownItem> */}
									</MDBDropdownMenu>
								</MDBDropdown>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink to="/collection/9" onClick={this.toggleCollapse}>Men</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<a className="nav-link" onClick={this.toggleCollapse} href="http://measure.kaavyaboutique.com/">
									Measurement
								</a>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink to="/collection/8" onClick={this.toggleCollapse}>Client Diaries</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink to="/about-us" onClick={this.toggleCollapse}>About Us</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink to="/contact-us" onClick={this.toggleCollapse}>Contact Us</MDBNavLink>
							</MDBNavItem>
						</MDBNavbarNav>
					</MDBCollapse>
				</div>
			</MDBNavbar>
		);
	}
}

export default NavbarTitle;
