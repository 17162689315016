import React, { Component } from 'react';

class Footer extends Component {
	state = {};

	render() {
		return (
			<div className="footer">
				<div className="container">
					<div className="get-updates">
						<h6>GET UPDATES</h6>
						<a href="https://www.instagram.com/kaavyaboutique/" target="_blank"><img src="https://img.icons8.com/color/48/000000/instagram-new.png"/></a>
						<a href="https://www.facebook.com/Kaavyaboutique/" target="_blank"><img src="https://img.icons8.com/color/48/000000/facebook-new.png"/></a>
						<a href="https://in.pinterest.com/Kaavyasof/" target="_blank"><img src="https://img.icons8.com/color/48/000000/pinterest.png"/></a>
						<a href="https://www.youtube.com/channel/UCxf5YEq-xtuKO2Y6p411IRA" target="_blank"><img src="https://img.icons8.com/color/48/000000/youtube-play.png"/></a>
						<a href="https://vm.tiktok.com/ZSX1CuSJ/" target="_blank"><img src="https://img.icons8.com/color/48/000000/tiktok.png"/></a>
						{/* <a href=""><i class="fab fa-tiktok" aria-hidden="true"></i></a> */}
						
					</div>
					<div className="row footer-info">
						<div className="col-md-6 copyright">
							<p>
								2020 &copy; <span>Kaavya Boutique</span>. All Rights Reserved
							</p>
						</div>
						<div className="offset-lg-1 col-md-6 col-lg-5 madeby">
							Made with <span>&hearts;</span> By{' '}
							<a target="_blank" rel="noopener noreferrer" href="https://www.ghimiremilan.com.np/">
								Milan Ghimire
							</a>{' '}
							and <a href="/">Kamal Dudharaj</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Footer;
