import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CaraouselSlider from './caraousel';
import { getCollection } from '../components/services/collectionService';
import Loader from '../components/utils/loader';
import NetworkErrorAlert from '../components/utils/networkAlert';
import { getCarousel } from '../components/services/caraouselService';
import { getTrending } from '../components/services/trendingService';
import { getWhatsnew } from '../components/services/whatsnewService';
import InstagramLink from './instagramLink';
import Footer from './footer';

class Collection extends Component {
	state = {
		CollectionData: [],
		CarouselData: [],
		TrendingData: [],
		WhatsNewData: [],
		loading: true,
		errors: {},
	};

	async componentDidMount() {
		try {
			const { data: CollectionData } = await getCollection();
			const { data: CarouselData } = await getCarousel();
			const { data: TrendingData } = await getTrending();
			const { data: WhatsNewData } = await getWhatsnew();
			this.setState(prevState => ({ CollectionData, CarouselData, TrendingData, WhatsNewData, loading: !prevState.loading }));
		} catch (err) {
			this.setState({ errors: err });
		}
	}

	render() {
		const { loading, errors, CollectionData, CarouselData, TrendingData, WhatsNewData } = this.state;

		return (
			<>
				{errors && errors.message ? (
					<NetworkErrorAlert />
				) : loading ? (
					<Loader />
				) : (
					<>
						<div className="main-collection">
							<CaraouselSlider data={CarouselData} />

{/* Trending and Whatsnew started */}
							<div className="container">
								<div className="row">
									{ (TrendingData && TrendingData[0]) &&
										<div className="col-lg-6 col-md-6">
											<div>
												<div className="title">
													<h2 className="c-title">Trending</h2>
												</div>
												<Link to={`/trending/${WhatsNewData[0].id}`}>
													<figure className="trending-image">
														<img src={`${TrendingData[0].imageUrl}`} alt="trendings"/>
													</figure>
												</Link>
											</div>
										</div>
									}
									{ (WhatsNewData && WhatsNewData[0]) &&
										<div className="col-lg-6 col-md-6">
											<div>
												<div className="title">
													<h2 className="c-title">What's New</h2>
												</div>
												<Link to={`/whatsnew/${WhatsNewData[0].id}`}>
													<figure className="trending-image">
														<img src={`${WhatsNewData[0].imageUrl}`} alt="checing"/>
													</figure>
												</Link>
											</div>
										</div>
									}
								</div>
							</div>

{/* Collection starts here */}

							<div className="container">
								<div className="title">
									<h2 className="c-title">Our Collection</h2>
								</div>
								<div className="row collection-wrapper">
									{CollectionData &&
										CollectionData.map(m => (
											<div className="col-lg-4 col-md-6 pb-5" key={m.id}>
												<div className="card-wrapper">
													<Link to={`/collection/${m.id}`}>
														<figure className="card-wrapper-image">
															<img src={`${m.imageUrl}`} alt={m.name} />
														</figure>
														<div className="card-wrapper-content">
															<h3 className="card-wrapper-title">{m.name}</h3>
														</div>
													</Link>
												</div>
											</div>
										))}
								</div>
							</div>
						</div>
						<InstagramLink />
						<Footer />
					</>
				)}
			</>
		);
	}
}

export default Collection;
