import React, { Component } from 'react';
import Loader from '../components/utils/loader';
import { getGalleryImage } from '../components/services/galleryService';
import NetworkErrorAlert from '../components/utils/networkAlert';
import Lightbox from 'react-image-lightbox';
import Footer from './footer';

class Gallery extends Component {
	state = {
		galleryData: [],
		loading: true,
		errors: false,
		photoIndex: 0,
		isOpen: false,
	};

	async componentDidMount() {
		try {
			const { data: galleryData } = await getGalleryImage(this.props.match.params.id);
			this.setState({ galleryData, loading: false });
		} catch (err) {
			this.setState({ errors: err });
		}
	}

	renderImages = () => {
		let photoIndex = -1;
		const { galleryData } = this.state;

		return (
			galleryData &&
			galleryData.map(m => {
				photoIndex++;
				const privateKey = photoIndex;
				return (
					<figure className="col-md-4 col-6" key={m.id} onClick={() => this.setState({ photoIndex: privateKey, isOpen: true })}>
						<img src={m.imageUrl} className="img-fluid z-depth-1" alt={m.name} />
						{m.sale && (
							<div className="ribbon">
								<div className="ribbon5">{`${m.salePercentage}% OFF`}</div>
							</div>
						)}
					</figure>
				);
			})
		);
	};

	render() {
		const { loading, errors, isOpen, galleryData, photoIndex } = this.state;
		return (
			<>
				{errors && errors.message ? (
					<NetworkErrorAlert />
				) : loading ? (
					<Loader />
				) : (
					<>
						<div className="gallery-wrap">
							<div className="container">
								<div className="row">{this.renderImages()}</div>
								{isOpen && (
									<Lightbox
										mainSrc={galleryData[photoIndex].imageUrl}
										nextSrc={galleryData[(photoIndex + 1) % galleryData.length].imageUrl}
										prevSrc={galleryData[(photoIndex + galleryData.length - 1) % galleryData.length].imageUrl}
										imageTitle={galleryData[photoIndex].name}
										onCloseRequest={() => this.setState({ isOpen: false })}
										onMovePrevRequest={() =>
											this.setState({
												photoIndex: (photoIndex + galleryData.length - 1) % galleryData.length,
											})
										}
										onMoveNextRequest={() =>
											this.setState({
												photoIndex: (photoIndex + 1) % galleryData.length,
											})
										}
									/>
								)}
							</div>
						</div>
						<Footer />
					</>
				)}
			</>
		);
	}
}

export default Gallery;
